import HeaderSection from 'Sections/HeaderSection/HeaderSection';
import OfferSlider from 'Sections/OfferSlider/OfferSlider';
import YoutubeSection from 'Sections/YoutubeSection/YoutubeSection';
export default {
	init() {

		HeaderSection.init();
		OfferSlider.init();
		YoutubeSection.init();

	},
	finalize() {
		// JavaScript to be fired on the home page, after the init JS
	},
};
