export default {
	init() {
		let script = document.createElement('script');
		script.addEventListener('load', () => {
			window.addEventListener('load', (event) => { 
				new hotresChooser({ 'maxAdults':10 });
			});
		});
		script.setAttribute('src', 'https://panel.hotres.pl/public/api/hotres_v4_chooser.js');
		document.body.append( script );

	},
	finalize() {

	}
}